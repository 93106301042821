<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="coa.family" class="form-group col-sm-12">
          <label for="familyId">Family:</label>
          <input class="form-control" disabled="" name="familyId" type="text" v-model="coa.family.familyName">
      </div>
      <div class="form-group col-sm-12 required">
          <label for="coaCategory">COA Category:</label>
        <Select2 name="coaCategory" required v-model="coa.coaCategory" :options="coaCategoryOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="coaName">COA Name:</label>
          <input class="form-control" required="" name="coaName" type="text" v-model="coa.coaName">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="coaCode">COA Code:</label>
        <input class="form-control" required="" name="coaCode" type="text" v-model="coa.coaCode">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/coas">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  export default {
    computed: {
        ...mapState('coas', ['coa', 'onRequest']),
        ...mapState('coa_categories', ['coa_categories']),
    },
    data(){
      return {
        coaCategoryOptions: [],
      }
    },
    components: {
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.coa)
      },
      ...mapActions('coas', ['edit', 'get']),
      ...mapActions('coa_categories', {get_categories: 'get_all'}),
    },
    created() {
      this.$emit('onChildInit', 'Edit Chart of Accounts')
      this.get_categories(),
      this.get(this.$route.params.id)
    },
    watch: {
      coa_categories(nextState, prevState){
        if(nextState !== prevState){
          this.coaCategoryOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.coaCategoryOptions.push({id:item, text:item})
            })
          }
          return
        }
      },
    }
  }
</script>
