<template>
  <div class="row">
    <div class="col-lg-12 col-sm-12 text-center">
      <h4>{{label}}</h4>
    </div>
    <div class="col-lg-12">
      <div class="row d-flex align-items-center">
        <div class="col-lg-6 col-sm-12 px-5">
          <DoughnutChart :chartData="chartData"/>
      </div>
        <div class="col-lg-6 col-sm-12">
        <div v-for="item in data" :key="item.label">
          <div :style="{marginBottom:'1rem'}" class="d-flex align-items-center">
            <div :style="{width:'1rem',height:'1rem',backgroundColor:item.color,marginRight:'1rem'}"/>
            <b>{{item.label}}:</b>&nbsp;&nbsp;{{item.data}}
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { DoughnutChart } from 'vue-chart-3';

export default defineComponent({
  components: { DoughnutChart },
  computed:{
    chartData(){
      if(this.data){
        let labels = []
        let datasets = []
        let dataset = {}
        let dataset_data = []
        let dataset_color = []
        this.data.map(item => {
          labels.push(item.label)
          dataset_data.push(item.data)
          dataset_color.push(item.color)
        })
        dataset.data = dataset_data
        dataset.backgroundColor = dataset_color
        datasets.push(dataset)
        return {
          labels: labels,
          datasets: datasets,
        }
      }
      return {
        labels: [],
        datasets:[],
      }
    }
  },
  props:['data', 'label'],
});
</script>
