<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="familyId">Family:</label>
        <Select2 name="familyId" v-model.number="familyId" :options="familyOptions"/>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="dateStart">From Date:</label>
        <input class="form-control" required type="date" v-model="dateStart">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="dateEnd">To Date:</label>
        <input class="form-control" required type="date" v-model="dateEnd">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
      </div>
    </div>
  </form>
  <Datatable v-if="events" v-bind:entries="events.content" :columns="columns" :title="'Events'"/>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  import moment from 'moment';
  export default {
    computed: {
      ...mapState('families', ['families']),
      ...mapState('users', ['user', 'profile']),
      ...mapState('events', ['events', 'onRequest']),
      columns(){
        return [
          {name: 'createdDate', text: 'Created At', raw: { type: 'Moment_UTC'}, order: 'desc'},
          {name: 'eventDescription', text: 'Event'},
          {name: 'eventType', text: 'Type'},
          {name: 'gps_lat_lng', text: 'Location', raw: {type: 'gps_lat_lng'}},
          {name: 'createdBy', text: 'Created By'},
          {name: 'modifiedBy', text: 'Modified By'},
          {name: 'modifiedDate', text: 'Modified At', raw: { type: 'Moment_UTC'}},
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                getCapability('EVENT_EDIT', this.user) ? {event: 'edit_event', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                getCapability('EVENT_DELETE', this.user) ? {event: 'events/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
              ]
            }
          },
        ]
      }
    },
    data(){
      return {        
        familyOptions: [],
        familyId: null,
        dateStart: moment().startOf('month').format("YYYY-MM-DD"),
        dateEnd: moment().endOf('month').format("YYYY-MM-DD"),
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('events', {get_data: 'get_all'}),
      ...mapActions('families', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {familyId, dateStart, dateEnd} = this
        let start = moment(dateStart).startOf('day').format('x')
        let end = moment(dateEnd).endOf('day').format('x')
        this.get_data({familyId, dateStart: start, dateEnd: end})
      },
    },
    created() {
      this.$emit('onChildInit', 'Events', getCapability('EVENT_ADD', this.user) ? '/events/add' : '')
      this.get_all()
      if(this.profile && this.profile.familyId){
        this.familyId =  this.profile.familyId
        this.handleSubmit()
      }
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          return
        }
      },
    }
  }
</script>
