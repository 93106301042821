<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="familyId">Family:</label>
        <Select2 name="familyId" required v-model="event.familyId" :options="familyOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <label for="eventDescription">Description:</label>
        <textarea class="form-control" name="eventDescription" v-model="event.eventDescription"></textarea>
      </div>  
      <div class="form-group col-sm-12">
        <label for="eventType">Event Type:</label>
        <Select2 name="eventType" v-model="event.eventType" :options="eventTypeOptions"/>
      </div> 
      <div class="form-group col-sm-4">
        <label for="eventType">Click here Tag Geo Location:</label>
        <br/>
        <input value="Get Location" class="btn btn-info" @click="getLocation"/>
      </div> 
      <div v-if="event.location" class="form-group col-sm-4">
        <label for="lat">Lat:</label>
        <input class="form-control" disabled name="lat" type="text" v-model="event.location.lat">
      </div>
      <div v-if="event.location" class="form-group col-sm-4">
        <label for="lng">Lng:</label>
        <input class="form-control" disabled name="lng" type="text" v-model="event.location.lng">
      </div>
      <InputImage label="Picture" v-on:selected-image="setImage" ref="imageComponent" disableBase64="true"/>
      <div v-if="this.picture" class="form-group col-sm-12">
        <input value="Clear Photos" class="btn btn-warning" @click="clearPhoto"/>
      </div> 
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/events">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  import InputImage from '../components/InputImage.vue'
  export default {
    computed: {
      ...mapState('families', ['families']),
      ...mapState('event_types', ['event_types']),
      ...mapState('users', ['user', 'profile']),
      ...mapState('events', ['onRequest']),
      ...mapState('photos', [{onRequestPhoto: 'onRequest'}, 'photo']),
    },
    components: {
      Select2,
      InputImage,
    },
    data(){
      return {
        event:{
          familyId: null,
          eventDescription: null,
          eventType: null,
          location: null,
          eventPhotos: [],
        },
        picture: null,
        familyOptions: [],
        eventTypeOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest || this.onRequestPhoto) return;
        this.clear()
        const {familyId, eventDescription, eventType, location, eventPhotos} = this.event
        if(eventDescription == null && eventPhotos.length < 1){
          this.error('At least a Description or a Photo is required')
          return;
        }
        this.add({ familyId, eventDescription, eventType, location, eventPhotos})
      },
      getLocation(){
        const self = this
        navigator.geolocation.getCurrentPosition(
          position => {
            self.event.location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
          },
          error => {
            alert(error.message);
          },
        )   
      },
      clearPhoto(){
        this.$refs.imageComponent.clearImage();
        this.event.eventPhotos = []
      },
      setImage(image){
        const formData = new FormData();
        formData.append('image',image)
        this.add_picture(formData)
      },
      ...mapActions('families', ['get_all']),
      ...mapActions('event_types', {get_event_types: 'get_all'}),
      ...mapActions('events', ['add']),
      ...mapActions('photos', {add_picture: 'add'}),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Add New Event')
      this.get_all()
      this.get_event_types()
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          if(this.familyOptions.length > 0){
            this.event.familyId = this.familyOptions[0].id
          }
          return
        }
      },
      photo(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.id){
            this.event.eventPhotos.push({photo: nextState})
          }
          return
        }
      },
      event_types(nextState, prevState){
        if(nextState !== prevState){
          this.eventTypeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.eventTypeOptions.push({id:item, text:item})
            })
          }
          return
        }
      },
    }
  }
</script>
