<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="familyId">Family:</label>
        <Select2 name="familyId" v-model.number="familyId" :options="familyOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
      </div>
    </div>
  </form>
  <Datatable v-bind:entries="checklists" :columns="columns" :title="'Checklists'"/>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('families', ['families']),
      ...mapState('users', ['user', 'profile']),
      ...mapState('checklists', ['checklists', 'onRequest']),
      columns(){
        return [
            {name: 'checklistName', text: 'Checklist Name'},
            {name: 'checked', text: 'Completed', class: 'text-right'},
            {name: 'total', text: 'Total', class: 'text-right'},
            {name: 'createdBy', text: 'Created By'},
            {name: 'createdDate', text: 'Created At', raw: { type: 'Moment_UTC'}},
            {name: 'modifiedBy', text: 'Modified By'},
            {name: 'modifiedDate', text: 'Modified At', raw: { type: 'Moment_UTC'}, order: 'desc'},
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('CHECKLIST_EDIT', this.user) ? {event: 'edit_checklist', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                  getCapability('CHECKLIST_DELETE', this.user) ? {event: 'checklists/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
        }
    },
    data(){
      return {        
        familyOptions: [],
        familyId: null
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('checklists', {get_data: 'get_all'}),
      ...mapActions('families', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {familyId} = this
        this.get_data({familyId})
      },
    },
    created() {
      this.$emit('onChildInit', 'Checklists', getCapability('CHECKLIST_ADD', this.user) ? '/checklists/add' : '')
      this.get_all()
      if(this.profile && this.profile.familyId){
        this.familyId =  this.profile.familyId
        this.handleSubmit()
      }
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          return
        }
      },
    }
  }
</script>
