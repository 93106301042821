<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="familyId">Family:</label>
        <Select2 name="familyId" required v-model="familyId" :options="familyOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="coaCategory">COA Category:</label>
        <Select2 name="coaCategory" required v-model="coaCategory" :options="coaCategoryOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="coaName">COA Name:</label>
          <input class="form-control" required="" name="coaName" type="text" v-model="coaName">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="coaCode">COA Code:</label>
        <input class="form-control" required="" name="coaCode" type="text" v-model="coaCode">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/coas">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  export default {
    computed: {
        ...mapState('families', ['families']),
        ...mapState('coa_categories', ['coa_categories']),
        ...mapState('users', ['profile']),
        ...mapState('coas', ['onRequest']),
    },
    components: {
      Select2
    },
    data(){
      return {
        familyId: null,
        coaCategory: null,
        coaName: '',
        coaCode: '',
        familyOptions: [],
        coaCategoryOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {familyId, coaCategory, coaName, coaCode} = this
        this.add({ familyId, coaCategory, coaName, coaCode })
      },
      ...mapActions('families', ['get_all']),
      ...mapActions('coa_categories', {get_categories: 'get_all'}),
      ...mapActions('currencies', {get_currencies: 'get_all'}),
      ...mapActions('coas', ['add']),
    },
    created() {
      this.$emit('onChildInit', 'Add New Chart of Accounts')
      this.get_all()
      this.get_categories()
      this.get_currencies()
      this.familyId = this.profile ? this.profile.familyId : null
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          return
        }
      },
      coa_categories(nextState, prevState){
        if(nextState !== prevState){
          this.coaCategoryOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.coaCategoryOptions.push({id:item, text:item})
            })
          }
          return
        }
      },
    }
  }
</script>
