<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="budgetName">Budget Name:</label>
          <input class="form-control" required="" name="budgetName" type="text" v-model="budget.budgetName">
      </div>  
      <div class="form-group col-sm-12 required">
        <label for="budgetPeriodType">Period:</label>
        <Select2 name="budgetPeriodType" required v-model="budget.budgetPeriodType" :options="budget_period_typesOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="currencyId">Currency:</label>
        <Select2 name="currencyId" required v-model.number="budget.currencyId" :options="currencyOptions"/>
      </div>    
      <div class="form-group col-sm-12 required">
        <label for="coaId">Expense COA:</label>
        <Select2 name="coaId" required v-model.number="budget.coaId" :options="coaOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="budgetAmount">Amount:</label>
        <input class="form-control" required="" name="budgetAmount" type="text" v-model="budget_amount_txt">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/budgets">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  import {formatNumber} from '../_helpers/calculation';
  export default {
    computed: {
      ...mapState('users', ['profile']),
      ...mapState('budgets', ['onRequest', 'budget']),
      ...mapState('currencies', ['currencies']),
      ...mapState('budget_period_types', ['budget_period_types']),
      ...mapState('coas', ['coas']),
      budget_amount_txt:{
        get() {
          return this.budget && this.budget.budgetAmount ? formatNumber(this.budget.budgetAmount) : ""
        },
        set(value) {
          this.budget.budgetAmount = Number(value.replaceAll(',',''))
        }
      }
    },
    components: {
      Select2
    },
    data(){
      return {
        familyOptions: [],
        coaOptions: [],
        currencyOptions: [],
        budget_period_typesOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.budget)
      },
      ...mapActions('budgets', ['edit', 'get']),
      ...mapActions('currencies', {get_currencies: 'get_all'}),
      ...mapActions('coas', {get_coas: 'get_all'}),
      ...mapActions('budget_period_types', {get_budget_period_types: 'get_all'}),
    },
    created() {
      this.$emit('onChildInit', 'Edit Checklist')
      this.get(this.$route.params.id)      
      this.get_currencies()
      this.get_budget_period_types()
    },
    watch: {
      budget(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.familyId != null){
            this.get_coas({familyId: nextState.familyId})
          }
        }
      },
      coas(nextState, prevState){
        if(nextState !== prevState){
          this.coaOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              if(item.coaCategory == 'EXPENSE')
              this.coaOptions.push({id:item.id, text:item.coaCode + ' - ' + item.coaName, coa: item})
            })    
            this.coaOptions = this.coaOptions.sort(function(a, b){
              if ( a.text < b.text ){
                return -1;
              }
              if ( a.text > b.text ){
                return 1;
              }
              return 0;
            })
          }
          return
        }
      },
      currencies(nextState, prevState){
        if(nextState !== prevState){
          this.currencyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.currencyOptions.push({id:item.id, text:item.currencyCode + ' - ' + item.currencyName})
            })
          }
          return
        }
      },
      budget_period_types(nextState, prevState){
        if(nextState !== prevState){
          this.budget_period_typesOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.budget_period_typesOptions.push({id:item, text:item})
            })
          }
          return
        }
      },
    }
  }
</script>
