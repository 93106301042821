<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="familyId">Family:</label>
        <Select2 name="familyId" required v-model="checklist.familyId" :options="familyOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="checklistName">Checklist Name:</label>
          <input class="form-control" required="" name="checklistName" type="text" v-model="checklist.checklistName">
      </div>
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12">
            <h4>Add Checklist Item</h4>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12">
            <label for="coaId">Item:</label>
            <input class="form-control" name="itemName" type="text" v-model="itemName">
          </div>
          <div class="form-group col-sm-12">
            <label for="checked">Is Completed:</label>
            <select class="form-control" name="checked" v-model="checked">
              <option value="true" style="color:black;">Yes</option>
              <option value="false" style="color:black;">No</option>
            </select>
          </div>
          <div class="form-group col-sm-12">
            <button v-if="this.isEdit == false" class="btn btn-success" @click.prevent="clickAdd($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAdd($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Is Completed</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in checklist.checklistItems" :key="index">
                <td>{{item.itemName}}</td>
                <td>{{item.checked}}</td>
                <td>                  
                  <button class="btn btn-sm btn-info" @click="clickEdit($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemove($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/checklists">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  export default {
    computed: {
        ...mapState('families', ['families']),
        ...mapState('users', ['profile']),
        ...mapState('checklists', ['onRequest']),
    },
    components: {
      Select2
    },
    data(){
      return {
        checklist: {          
          familyId: null,
          checklistName: null,
          checklistItems: [],
        },
        isEdit: false,
        editIndex: null,
        familyOptions: [],
        itemName: null,
        checked: false,
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {familyId, checklistName, checklistItems} = this.checklist
        if(checklistItems.length < 1){
          this.error('At least 1 Checklist Item required')
          return;
        }
        this.add({ familyId, checklistName, checklistItems })
      },
      ...mapActions('families', ['get_all']),
      ...mapActions('checklists', ['add']),
      ...mapActions('alert', ['error', 'clear']),
      clickAdd(){
        const {itemName, checked} = this
        if(this.itemName === null){
          return;
        }
        let obj = {itemName, checked: checked == "true" ? true : false}
        if(this.isEdit){
          this.checklist.checklistItems[this.editIndex] = obj
        } else {
          this.checklist.checklistItems.push(obj)
        }
        
        this.resetData()
      },      
      clickEdit(event, index){
        event.preventDefault()
        const {itemName, checked} = this.checklist.checklistItems[index]
        this.itemName= itemName
        this.checked = checked
        this.isEdit = true
        this.editIndex = index
      },
      clickRemove(event, index){
        event.preventDefault()
        this.checklist.checklistItems.splice(index, 1)
        this.resetData()
      },
      resetData(){
        this.isEdit= false
        this.editIndex= null
        this.itemName= null
        this.checked = false
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Checklist')
      this.get_all()
      this.checklist.familyId = this.profile ? this.profile.familyId : null
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          return
        }
      },
    }
  }
</script>
