<template>
  <router-view />
</template>

<script>
import axios from 'axios';
export default {
  name: 'App',
  created: function () {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        throw err;
      });
    });
  },
}
</script>
