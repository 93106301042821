<template>
  <div v-if="entries && columns" :key="entries">
    <div class="table-responsive">
      <table class="table table-bordered table-striped" id="table_data">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column" :class="(column.raw && column.raw.type == 'Action' ? 'notexport' : '')">{{column.text}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="entries.length < 1" :key="entries.length">
            <td :colspan="columns.length" class="text-center">No data available in table</td>
          </tr>
          <tr v-for="(data, index) in entries" :key="index" @click="onRowClicked(data)" :class="(this.onRowClickEvent && this.selectedRow != null && this.selectedRow == data ? 'selected' : '')">
            <td v-for="column in columns" :key="column" :class="(this.onRowClickEvent ? 'hoverable' : '')+' '+ column.class">
              <span v-if="column.raw">
                <span v-if="column.raw.type == 'Text'">
                  <span v-for="field in column.raw.fields" :key="field">
                    <span v-if="field.value == data[column.name]" :class="field.class">{{field.text}}</span>
                  </span>
                </span>
                <span v-if="column.raw.type == 'Action'" style="display:flex;">
                  <span v-for="field in column.raw.fields" :key="field">
                    <router-link v-if="field.method == 'get' && checkEnable(field, data)" :class="field.class" :to="{ name: field.event, params: {id: data.id }}">{{field.text}}</router-link>
                    <router-link v-if="field.method == 'get_newtab' && checkEnable(field, data)" :class="field.class" :to="{ name: field.event, params: {id: data.id }}" target="_blank">{{field.text}}</router-link>
                    <a v-if="field.method == 'open_newtab'" target="_blank" :class="field.class" :href="data[field.url]">{{field.text}}</a>
                    <form v-if="field.method == 'post' && checkEnable(field, data)" @submit.prevent="handleSubmit(field.event, data.id)">
                      <button :class="field.class">{{field.text}}</button>
                    </form>
                  </span>
                </span>
                <span v-if="column.raw.type == 'Sub'">
                  <span v-if="data[column.name] != null">
                    {{data[column.name][column.raw.value]}}
                  </span>
                </span>
                <span v-if="column.raw.type == 'Sub_text'">
                  <span v-if="data[column.name] != null">
                    <span v-for="field in column.raw.fields" :key="field">
                      <span v-if="field.value == data[column.name][column.raw.value]" :class="field.class">{{field.text}}</span>
                    </span>
                  </span>
                </span>
                <span v-if="column.raw.type == 'Sub_sub'">
                  <span v-if="data[column.name] != null && data[column.name][column.raw.sub] != null">
                    {{data[column.name][column.raw.sub][column.raw.value]}}
                  </span>
                </span>
                <span v-if="column.raw.type == 'Sub_format_number_acc'">
                  <span v-if="data[column.name] != null">
                    {{formatNumberAcc(data[column.name][column.raw.value], 2)}}
                  </span>
                </span>
                <span v-if="column.raw.type == 'Color'" v-bind:style="{backgroundColor:data[column.name],padding:'5px',color:'white'}">
                  {{data[column.name]}}
                </span>
                <span v-if="column.raw.type == 'Moment'">
                  {{convertMoment(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'format_number'">
                  {{formatNumber(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'format_number_acc'">
                  {{formatNumberAcc(data[column.name], 2)}}
                </span>
                <span v-if="column.raw.type == 'decimal_number'">
                  {{(data[column.name] !== undefined ? (data[column.name]).toFixed(column.raw.decimal > -1 ? column.raw.decimal : 0) : "")}}
                </span>
                <span v-if="column.raw.type == 'decimal_number_pct'">
                  {{(data[column.name] !== undefined ? (data[column.name]).toFixed(column.raw.decimal > -1 ? column.raw.decimal : 0) + "%" : "")}}
                </span>
                <span v-if="column.raw.type == 'Moment_UTC'">
                  {{convertUtc(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'Sub_Moment_UTC'">
                  <span v-if="data[column.name] != null">
                    {{convertUtc(data[column.name][column.raw.value])}}
                  </span>
                </span>
                <span v-if="column.raw.type == 'Sub_format_number'">
                  <span v-if="data[column.name] != null">
                    {{formatNumber(data[column.name][column.raw.value])}}
                  </span>
                </span>
                <span v-if="column.raw.type == 'Moment_Time'">
                  {{convertTime(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'Moment_date'">
                  {{convertDate(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'Moment_date_expiring'" :style="'color:'+getColor(data[column.name])">
                  {{convertDateExpiring(data[column.name])}}
                </span>
                <span v-if="data.location && column.raw.type == 'gps_lat_lng'">
                  <a target="_blank" :href="'https://maps.google.com/?q='+data.location.lat+','+data.location.lng">Google</a>
                </span>
              </span>
              <span v-else>{{data[column.name]}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else>
  </div>
</template>
<script>
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons-dt"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery';
import moment from 'moment'
import {formatNumber, formatNumberAcc} from '../_helpers/calculation';
export default {
  data(){
    return {
      dt: null,
      selectedRow: null
    }
  },
  watch:{
    entries(nextState){
      if(!nextState || nextState.length < 1) return;
      const self = this
      this.$nextTick(() => {
        let orderType = 'asc', orderIndex = 0
        self.columns.map((item, index) => {
          if(item.order){
            orderType = item.order
            orderIndex = index
          }
        })
        self.dt = $('#table_data').DataTable({
          dom: 'Bfrtip',
          buttons: ['pageLength', {
              extend: 'excel',
              title: this.title ? this.title : '',
              exportOptions: {
                  columns: ':not(.notexport)'
              }
          },{
              extend: 'pdf',
              title: this.title ? this.title : '',
              exportOptions: {
                  columns: ':not(.notexport)'
              },
              orientation:'landscape',
              customize: function (doc) {
                doc.content[1].table.widths = Array(doc.content[1].table.body[0].length + 1).join('*').split('');
              }
          },{
              extend: 'csv',
              title: this.title ? this.title : '',
              exportOptions: {
                  columns: ':not(.notexport)'
              },
          },{
              extend: 'print',
              title: this.title ? this.title : '',
              exportOptions: {
                  columns: ':not(.notexport)'
              },
          }],
          processing: true,
          bPaginate: true,
          "pageLength": self.currEntries ? self.currEntries : 10,
          order: [orderIndex, orderType]
        })
      });
    }
  },
  methods:{
    handleSubmit(event, id){
      if(confirm('Are you sure?')){
        this.$store.dispatch(event, id, { root: true })
      }
    },
    convertMoment(item){
      if(item)
        return moment(item, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
      else
        return ''
    },
    convertUtc(item){
      if(item)
        return moment.utc(item, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
      else
        return ''
    },
    convertDate(item){
      if(item)
        return moment.utc(item, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD")
      else
        return ''
    },
    convertTime(item){
      if(item)
        return moment.utc(item, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
      else
        return ''
    },
    convertDateExpiring(item){
      if(item){
        let day = moment(item).fromNow()
        return moment.utc(item, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD") + " ( "+day+" )"
      }
      else
        return ''
    },
    formatNumber(value){
      return formatNumber(value)
    },
    formatNumberAcc(value, decimal){
      return formatNumberAcc(value, decimal)
    },
    onRowClicked(item){
      if(this.onRowClickEvent){
        this.selectedRow = item
        this.onRowClickEvent(item)
      }
    },
    getColor(item){
      let day = moment(item).diff(moment(), 'days')
      if(day <= 30){
        return '#dc3545'
      }
      return '#ffc107'
    },
    checkEnable(field, data){
      if(!field.check) {
        return true
      }
      if(data[field.check.name] == field.check.value){
        return true
      }
      return false
    },
  },
  props:['title', 'entries', 'columns', 'currEntries', 'onRowClickEvent'],
}
</script>
<style>
.hoverable {
  cursor: pointer;
}
.selected{
  background-color: #3bb143 !important
}
@media print {
  body * {
    visibility: hidden;
  }
  body{
    -webkit-transform:unset;
    transform:unset;
    min-width: unset !important;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    margin:auto;
    height: auto;
    font-family: Arial;
    line-height: 1.5;
  }
  #section-to-print table tr th {
    padding: 10px;
  }
  #section-to-print table tr td {
    padding: 10px 10px;
  }
}
</style>
