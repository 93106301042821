<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="currencyName">Currency Name:</label>
          <input class="form-control" required="" name="currencyName" type="text" v-model="currencyName">
      </div>
      <div class="form-group col-sm-12 required">
          <label for="currencyCode">Currency Code:</label>
          <input class="form-control" required="" name="currencyCode" type="text" v-model="currencyCode">
      </div>
      <div class="form-group col-sm-12 required">
          <label for="amountUsd">Currency to USD:</label>
          <input class="form-control" required="" name="amountUsd" type="number" v-model.number="amountUsd" step="any">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/currencies">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('currencies', ['currencies', 'onRequest']),
    },
    data(){
      return {
        currencyName: '',
        currencyCode: '',
        amountUsd: '',
      }
    },
    methods: {
      ...mapActions('currencies', ['add']),
      handleSubmit(){
        if(this.onRequest) return;
        const {currencyName, currencyCode, amountUsd} = this
        this.add({ currencyName, currencyCode, amountUsd })
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Currency')
    },
  }
</script>
